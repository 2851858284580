<template>
  <Layout>
    <div class="row">
      <div class="col-12">
        <add-driver-modal @reloadData="getAllUser" />
        <div class="card">
          <div class="card-body">
            <div class="row mt-4">
              <div class="col-sm-12 col-md-6">
                <div id="tickets-table_length" class="dataTables_length">
                  <label class="d-inline-flex align-items-center">
                    Show&nbsp;
                    <b-form-select
                      v-model="perPage"
                      size="sm"
                      :options="pageOptions"
                    ></b-form-select
                    >&nbsp;entries
                  </label>
                </div>
              </div>
              <!-- Search -->
              <div class="col-sm-12 col-md-6">
                <div
                  id="tickets-table_filter"
                  class="dataTables_filter text-md-right"
                >
                  <label class="d-inline-flex align-items-center">
                    Search:
                    <b-form-input
                      v-model="filter"
                      type="search"
                      placeholder="Search..."
                      class="form-control form-control-sm ml-2"
                    ></b-form-input>
                  </label>
                </div>
              </div>
              <!-- End search -->
            </div>
            <!-- Table -->
            <div class="table-responsive mb-0">
              <b-table
                :items="driverData"
                :fields="fields"
                responsive="sm"
                :busy="isBusy"
                :per-page="perPage"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                tbody-tr-class="rowClass"
                :filter-included-fields="filterOn"
                @row-clicked="driverSelected"
              >
                <template #table-busy>
                  <div class="text-center text-danger my-2">
                    <b-spinner class="align-middle"></b-spinner>
                  </div>
                </template>
                <template #cell(phone_number)="row">
                  +{{ row.item.country_code }}-{{ row.item.phone_number }}
                </template>
                <template #cell(email)="row">
                  {{ row.item.email }} <br />
                  <b-button
                    @click="openFCMModel(row.item)"
                    style="display: flex; padding: 2px 6px; font-size: 12px"
                  >
                    <span style="margin-right: 4px; margin-top: 1px">
                      Send FCM</span
                    >
                    <i class="ri-send-plane-2-line"></i>
                  </b-button>
                </template>
                <template #cell(action)="row">
                  <b-button
                    variant="danger"
                    @click="deleteDriver(row.item._id)"
                  >
                    Delete
                  </b-button>
                </template>
              </b-table>
            </div>
            <div class="row">
              <div class="col">
                <div
                  class="dataTables_paginate paging_simple_numbers float-right"
                >
                  <ul class="pagination pagination-rounded mb-0">
                    <!-- pagination -->
                    <b-pagination
                      v-model="currentPage"
                      :total-rows="totalRows"
                      :per-page="perPage"
                      @change="updatePage"
                    ></b-pagination>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <FcmModal :selectedDriver="selectedDriver" />
  </Layout>
</template>

<script>
import Layout from "../../layouts/main";
import { driver } from "@/config/api/drivers";
import FcmModal from "@/components/notification/modal";
import AddDriverModal from "@/components/driver/AddDriver";
import { user } from "@/config/api/users";

export default {
  components: { Layout, FcmModal, AddDriverModal },
  data() {
    return {
      driverData: [],
      isBusy: false,
      totalRows: 1,
      selectedDriver: null,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      filterOn: [],
      sortBy: "age",
      sortDesc: false,
      fields: [
        { key: "email", sortable: false },
        { key: "phone_number", sortable: false },
        { key: "driver_id", sortable: false },
        { key: "action", sortable: false },
      ],
    };
  },
  computed: {},
  mounted() {},
  created() {
    this.getAllUser();
  },
  watch: {
    filter: function (newVal) {
      if (newVal.length > 2 || newVal.length === 0) {
        setTimeout(() => {
          this.getAllUser(1, this.filter);
        }, 2000);
      }
    },
  },
  methods: {
    // findDriver() {
    //     this.getAllUser(1, this.filter);
    // },
    
    updatePage(page) {
      this.getAllUser(page);
    },
    getAllUser(page = 1, search = "") {
      this.isBusy = true;
      const api = driver.get;
      api.params = { page: page, search: search };
      this.generateAPI(api)
        .then((res) => {
          this.driverData = res.data.drivers;
          this.totalRows = res.data.total_no_of_documents;
          this.isBusy = false;
        })
        .catch((err) => {
          console.error(err.response.data);
        });
    },
    openFCMModel(item) {
      this.selectedDriver = item;
      this.$bvModal.show("fcm-modal");
    },
    driverSelected(data) {
      this.$router.push({ name: "DriverDetails", params: { id: data._id } });
      console.log(data);
    },
    deleteDriver(id) {
      this.$bvModal
        .msgBoxConfirm("Please confirm that you want to delete user.", {
          title: "Please Confirm",
          size: "sm",
          buttonSize: "sm",
          okVariant: "danger",
          okTitle: "YES",
          cancelTitle: "NO",
          footerClass: "p-2",
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if (value) {
            const api = user.delete;
            api.id = id;
            this.generateAPI(api)
              .then(() => {
                this.$bvToast.toast("Driver deleted successfully!", {
                  title: "Delete",
                  variant: "success",
                  solid: true,
                });
                this.getAllUser(this.currentPage);
              })
              .catch((err) => {
                this.$bvToast.toast(
                  `Driver deleted successfully, ${err.response.data}!`,
                  {
                    title: "Delete",
                    variant: "danger",
                    solid: true,
                  }
                );
              });
          }
        });
    },
  },
};
</script>

<style>
.rowClass {
  cursor: pointer;
}

.rowClass:hover {
  background: #f2f2f2;
  /* color: #ffffff; */
}
</style>
