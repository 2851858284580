<template>
    <div class="add-driver">
        <b-button v-b-modal.add-driver-modal>Add Driver</b-button>

        <b-modal id="add-driver-modal" title="Add Driver" hide-footer>
            <FormulateForm @submit="addDriver" class="add-driver-form" v-model="formValues">
                <div class="row">
                    <div class="col-sm-6">
                        <FormulateInput name="full_name" type="text" label="Full name" placeholder="Full name"
                            validation="required" />
                    </div>
                    <div class="col-sm-6">
                        <FormulateInput name="email" type="email" label="Email address" placeholder="Email address"
                            validation="required|email" />
                    </div>
                </div>
                <div class="row">
                    <div class="col-3">
                        <FormulateInput name="country_code" type="number" label="Country code"
                            placeholder="Country code" validation="required" />
                    </div>
                    <div class="col-9">
                        <FormulateInput name="phone_number" type="number" label="Phone number"
                            placeholder="Phone number" validation="required" />
                    </div>
                </div>


                <div class="row">
                    <div class="col-sm-6">
                        <FormulateInput name="license_number" type="text" label="License number"
                            placeholder="License number" validation="required" />
                    </div>
                    <div class="col-sm-6">
                        <FormulateInput name="expiry_date" type="date" label="Expiry date" placeholder="Expiry date"
                            validation="required" />
                    </div>
                </div>
                <div class="row  mb-4">
                    <div class="col-sm-6">
                        <FormulateInput name="nationality" type="text" label="Nationality" placeholder="Nationality"
                            validation="required" />
                    </div>
                </div>

                <div class="float-right">
                    <FormulateInput type="submit" :label="submitting ? 'Registering...' : 'Register'" />
                </div>
            </FormulateForm>
        </b-modal>
    </div>
</template>

<script>

import { driver } from "@/config/api/drivers"
import {getFormData} from "@/utils/createForm"
export default {
    data() {
        return {
            formValues: {},
            submitting: false,
        }
    },
    methods: {
        addDriver(data) {
            const api = driver.createDriver
            data.expiry_date = this.moment(data.expiry_date).format('MM-DD-yyyy')
            api.data = getFormData(data)
            this.submitting = true
            this.generateAPI(api)
                .then(() => {
                    // this.$formulate.reset("updateModelForm");
                    this.$bvToast.toast("Driver added successfully!", {
                        title: "Create",
                        variant: "success",
                        solid: true,
                    });
                    this.$emit("reloadData");
                    this.$bvModal.hide('add-driver-modal')
                })
                .catch((err) => {
                    this.$bvToast.toast(`Driver is not added, ${err.response.data.error}!`, {
                        title: "Create",
                        variant: "danger",
                        solid: true,
                    });
     
                })
                .finally(() => {
                    this.submitting= false;
                });

        }
    }
}
</script>

<style >
.add-driver {
    margin: 10px;
    display: flex;
    justify-content: flex-end;
}
</style>

